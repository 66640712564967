import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { config } from '../config';
import { formatTimeAgo } from '../utils/formatters';

export default function SyncManagement() {
  const queryClient = useQueryClient();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const { data: syncStatus, isLoading } = useQuery({
    queryKey: ['sync-status'],
    queryFn: async () => {
      const response = await axios.get(`${config.apiUrl}/api/admin/sync/status`, {
        headers: config.getAuthHeaders()
      });
      return response.data;
    },
    refetchInterval: 5000 // Refresh every 5 seconds while sync is running
  });

  const triggerSyncMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        `${config.apiUrl}/api/admin/sync`,
        {},
        {
          headers: config.getAuthHeaders()
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['sync-status']);
      setSuccess('Sincronizare pornită cu succes');
      setTimeout(() => setSuccess(null), 3000);
    },
    onError: (error) => {
      console.error('Sync error:', error);
      setError(error.response?.data?.message || 'Eroare la pornirea sincronizării');
      setTimeout(() => setError(null), 3000);
    }
  });

  if (isLoading) {
    return (
      <div className="text-center py-4">
        <div className="text-gray-600">Se încarcă...</div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Sincronizare Media</h2>
        <button
          onClick={() => triggerSyncMutation.mutate()}
          disabled={syncStatus?.isRunning}
          className={`px-4 py-2 text-sm font-medium text-white rounded-md ${
            syncStatus?.isRunning
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-primary-600 hover:bg-primary-700'
          }`}
        >
          {syncStatus?.isRunning ? 'Sincronizare în curs...' : 'Pornește sincronizare'}
        </button>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error}</h3>
            </div>
          </div>
        </div>
      )}

      {success && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">{success}</h3>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Status Sincronizare
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {syncStatus?.isRunning ? (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Activ
                  </span>
                ) : (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    Inactiv
                  </span>
                )}
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Ultima sincronizare</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {syncStatus?.lastSyncEnd ? formatTimeAgo(syncStatus.lastSyncEnd) : 'Niciodată'}
              </dd>
            </div>

            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Statistici ultima sincronizare</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                  <div className="bg-gray-50 px-4 py-3 rounded-lg">
                    <p className="text-sm font-medium text-gray-500">Filme procesate</p>
                    <p className="mt-1 text-2xl font-semibold text-gray-900">
                      {syncStatus?.lastSyncStats?.moviesProcessed || 0}
                    </p>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 rounded-lg">
                    <p className="text-sm font-medium text-gray-500">Seriale procesate</p>
                    <p className="mt-1 text-2xl font-semibold text-gray-900">
                      {syncStatus?.lastSyncStats?.seriesProcessed || 0}
                    </p>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 rounded-lg">
                    <p className="text-sm font-medium text-gray-500">Titluri noi</p>
                    <p className="mt-1 text-2xl font-semibold text-gray-900">
                      {syncStatus?.lastSyncStats?.newItemsAdded || 0}
                    </p>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 rounded-lg">
                    <p className="text-sm font-medium text-gray-500">Titluri actualizate</p>
                    <p className="mt-1 text-2xl font-semibold text-gray-900">
                      {syncStatus?.lastSyncStats?.itemsUpdated || 0}
                    </p>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 rounded-lg">
                    <p className="text-sm font-medium text-gray-500">Marcate indisponibile</p>
                    <p className="mt-1 text-2xl font-semibold text-gray-900">
                      {syncStatus?.lastSyncStats?.itemsMarkedUnavailable || 0}
                    </p>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 rounded-lg">
                    <p className="text-sm font-medium text-gray-500">Erori</p>
                    <p className="mt-1 text-2xl font-semibold text-gray-900">
                      {syncStatus?.lastSyncStats?.errors || 0}
                    </p>
                  </div>
                </div>
              </dd>
            </div>

            {syncStatus?.lastSyncStart && syncStatus?.lastSyncEnd && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Durată ultima sincronizare</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {Math.round((new Date(syncStatus.lastSyncEnd) - new Date(syncStatus.lastSyncStart)) / 1000)} secunde
                </dd>
              </div>
            )}

            {syncStatus?.lastSyncStats?.errors > 0 && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  <button
                    onClick={() => setShowErrorDetails(!showErrorDetails)}
                    className="text-primary-600 hover:text-primary-700"
                  >
                    {showErrorDetails ? 'Ascunde detalii erori' : 'Arată detalii erori'}
                  </button>
                </dt>
                {showErrorDetails && (
                  <dd className="mt-1 text-sm text-gray-900 bg-red-50 p-4 rounded-md">
                    <ul className="list-disc pl-5 space-y-1">
                      {syncStatus.lastSyncStats.errorDetails.map((error, index) => (
                        <li key={index} className="text-red-700">{error}</li>
                      ))}
                    </ul>
                  </dd>
                )}
              </div>
            )}
          </dl>
        </div>
      </div>
    </div>
  );
}
