import { useState } from 'react';
import UserManagement from '../components/UserManagement';
import StatusManagement from '../components/StatusManagement';
import RequestManagement from '../components/RequestManagement';
import SyncManagement from '../components/SyncManagement';

export default function AdminDashboard() {
  const [activeTab, setActiveTab] = useState('requests');

  const tabs = [
    { id: 'requests', name: 'Cereri' },
    { id: 'users', name: 'Utilizatori' },
    { id: 'statuses', name: 'Statusuri' },
    { id: 'sync', name: 'Sincronizare' }
  ];

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Administrare</h1>
        <p className="mt-2 text-sm text-gray-700">
          Gestionați cererile, utilizatorii, statusurile și sincronizarea aplicației
        </p>
      </div>

      <div>
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                  ${
                    activeTab === tab.id
                      ? 'border-primary-500 text-primary-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }
                `}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>

        <div className="mt-6">
          {activeTab === 'requests' && <RequestManagement />}
          {activeTab === 'users' && <UserManagement />}
          {activeTab === 'statuses' && <StatusManagement />}
          {activeTab === 'sync' && <SyncManagement />}
        </div>
      </div>
    </div>
  );
}
