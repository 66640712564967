import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { config } from '../config';

function StatusBadge({ status, onEdit }) {
  return (
    <div
      className="flex items-center justify-between p-2 rounded-md"
      style={{ backgroundColor: status.color + '20' }}
    >
      <div className="flex items-center space-x-2">
        <div
          className="w-4 h-4 rounded-full"
          style={{ backgroundColor: status.color }}
        />
        <span className="font-medium" style={{ color: status.color }}>
          {status.name}
          {status.isDefaultForRequests && (
            <span className="ml-2 text-xs text-gray-500">(Implicit pentru cereri)</span>
          )}
          {status.isDefaultForMonthlyTitles && (
            <span className="ml-2 text-xs text-gray-500">(Implicit pentru titluri lunare)</span>
          )}
        </span>
      </div>
      <button
        onClick={() => onEdit(status)}
        className="text-gray-600 hover:text-gray-900"
      >
        Editează
      </button>
    </div>
  );
}

function StatusForm({ status, onSubmit, onCancel }) {
  const [name, setName] = useState(status?.name || '');
  const [color, setColor] = useState(status?.color || '#808080');
  const [isDefaultForRequests, setIsDefaultForRequests] = useState(status?.isDefaultForRequests || false);
  const [isDefaultForMonthlyTitles, setIsDefaultForMonthlyTitles] = useState(status?.isDefaultForMonthlyTitles || false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ 
      name, 
      color, 
      isDefaultForRequests,
      isDefaultForMonthlyTitles
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Nume</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="input mt-1"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Culoare</label>
        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          className="mt-1 p-1 w-full h-10 rounded-md border border-gray-300"
          required
        />
      </div>
      <div className="space-y-2">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="isDefaultForRequests"
            checked={isDefaultForRequests}
            onChange={(e) => setIsDefaultForRequests(e.target.checked)}
            className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
          />
          <label htmlFor="isDefaultForRequests" className="ml-2 block text-sm text-gray-900">
            Status implicit pentru cereri noi
          </label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="isDefaultForMonthlyTitles"
            checked={isDefaultForMonthlyTitles}
            onChange={(e) => setIsDefaultForMonthlyTitles(e.target.checked)}
            className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
          />
          <label htmlFor="isDefaultForMonthlyTitles" className="ml-2 block text-sm text-gray-900">
            Status implicit pentru titluri lunare
          </label>
        </div>
      </div>
      <div className="flex justify-end space-x-2">
        <button type="button" onClick={onCancel} className="btn btn-secondary">
          Anulează
        </button>
        <button type="submit" className="btn btn-primary">
          Salvează
        </button>
      </div>
    </form>
  );
}

export default function StatusManagement() {
  const [editingStatus, setEditingStatus] = useState(null);
  const [showNewStatusForm, setShowNewStatusForm] = useState(false);
  const queryClient = useQueryClient();

  const { data: statuses, isLoading: isLoadingStatuses } = useQuery({
    queryKey: ['statuses'],
    queryFn: async () => {
      const response = await axios.get(`${config.apiUrl}/api/admin/statuses`, {
        headers: config.getAuthHeaders()
      });
      return response.data;
    }
  });

  const createStatusMutation = useMutation({
    mutationFn: async (statusData) => {
      const response = await axios.post(
        `${config.apiUrl}/api/admin/statuses`,
        statusData,
        {
          headers: config.getAuthHeaders()
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['statuses']);
      setShowNewStatusForm(false);
    }
  });

  const updateStatusMutation = useMutation({
    mutationFn: async ({ id, data }) => {
      const response = await axios.put(
        `${config.apiUrl}/api/admin/statuses/${id}`,
        data,
        {
          headers: config.getAuthHeaders()
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['statuses']);
      setEditingStatus(null);
    }
  });

  const handleStatusSubmit = async (data) => {
    if (editingStatus) {
      updateStatusMutation.mutate({ id: editingStatus._id, data });
    } else {
      createStatusMutation.mutate(data);
    }
  };

  if (isLoadingStatuses) {
    return (
      <div className="text-center py-4">
        <div className="text-gray-600">Se încarcă...</div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Statusuri</h2>
        {!showNewStatusForm && !editingStatus && (
          <button
            onClick={() => setShowNewStatusForm(true)}
            className="btn btn-primary"
          >
            Status Nou
          </button>
        )}
      </div>

      {showNewStatusForm && (
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="text-lg font-medium mb-4">Status Nou</h3>
          <StatusForm
            onSubmit={handleStatusSubmit}
            onCancel={() => setShowNewStatusForm(false)}
          />
        </div>
      )}

      <div className="space-y-4">
        {statuses?.map((status) =>
          editingStatus?._id === status._id ? (
            <div key={status._id} className="p-4 bg-gray-50 rounded-lg">
              <StatusForm
                status={status}
                onSubmit={handleStatusSubmit}
                onCancel={() => setEditingStatus(null)}
              />
            </div>
          ) : (
            <StatusBadge
              key={status._id}
              status={status}
              onEdit={setEditingStatus}
            />
          )
        )}
      </div>
    </div>
  );
}
