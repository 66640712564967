import React from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';

const NoImagePlaceholder = ({ className = '' }) => {
  return (
    <div className={`flex items-center justify-center bg-gray-200 dark:bg-gray-700 ${className}`}>
      <div className="text-center p-4">
        <PhotoIcon className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500" />
        <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
          Fără imagine
        </p>
      </div>
    </div>
  );
};

export default NoImagePlaceholder;
