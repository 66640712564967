import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function SeriesRequestModal({ isOpen, onClose, onConfirm, item = null }) {
  const [seasonNumber, setSeasonNumber] = useState('');

  // Reset season number when modal is opened/closed
  useEffect(() => {
    if (!isOpen) {
      setSeasonNumber('');
    }
  }, [isOpen]);

  // Don't render anything if modal is not open or item is null
  if (!isOpen || !item) return null;

  const handleSeasonRequest = () => {
    if (seasonNumber) {
      onConfirm('season', parseInt(seasonNumber, 10));
    }
  };

  const isSeasonAvailable = (seasonNum) => {
    // Don't consider season 0 as available
    if (seasonNum === 0) return false;
    return item.availableSeasons?.some(season => season.seasonNumber === seasonNum);
  };

  const getAvailabilityText = () => {
    if (!item.isAvailable) return null;
    
    const texts = [`Acest serial este disponibil în categoria ${item.categoryName}`];
    
    // Use season_count for total number of seasons
    if (item.season_count) {
      texts.push(`${item.season_count} sezoane`);
    }

    // Show which seasons are available, excluding season 0
    if (item.availableSeasons?.length > 0) {
      const availableSeasonNumbers = item.availableSeasons
        .map(season => season.seasonNumber)
        .filter(num => num > 0) // Filter out season 0
        .sort((a, b) => a - b);
      if (availableSeasonNumbers.length > 0) {
        texts.push(`Sezoane disponibile: ${availableSeasonNumbers.join(', ')}`);
      }
    }

    return texts;
  };

  const getSeasonInputClass = () => {
    if (!seasonNumber) return '';
    return isSeasonAvailable(parseInt(seasonNumber, 10))
      ? 'border-yellow-500 focus:border-yellow-500 focus:ring-yellow-500'
      : '';
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Solicită Serial
              </h3>
              <div className="mt-4 space-y-4">
                <div className="flex items-center space-x-4">
                  {item.poster_path && (
                    <img
                      src={`https://image.tmdb.org/t/p/w200${item.poster_path}`}
                      alt={item.title || item.name}
                      className="w-24 h-36 object-cover rounded"
                    />
                  )}
                  <div className="flex-1">
                    <h4 className="text-base font-medium text-gray-900">
                      {item.title || item.name}
                    </h4>
                    <p className="text-sm text-gray-500">
                      {new Date(item.release_date || item.first_air_date).getFullYear()}
                    </p>
                    {item.isAvailable && (
                      <div className="mt-2 space-y-1">
                        {getAvailabilityText()?.map((text, index) => (
                          <p key={index} className="text-sm text-gray-600">
                            {text}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <h4 className="text-sm font-medium text-gray-900 mb-2">
                      Alege tipul cererii:
                    </h4>
                    <div className="space-y-2">
                      <button
                        onClick={() => onConfirm('new')}
                        className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      >
                        Solicită Serial Nou
                      </button>
                      <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                          <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                          <span className="px-2 bg-white text-gray-500">sau</span>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <input
                          type="number"
                          min="1"
                          max={item.season_count || 999}
                          placeholder="Sezon"
                          value={seasonNumber}
                          onChange={(e) => setSeasonNumber(e.target.value)}
                          className={`block w-24 rounded-md border-gray-300 shadow-sm focus:ring-primary-500 text-sm ${getSeasonInputClass()}`}
                        />
                        <button
                          onClick={handleSeasonRequest}
                          disabled={!seasonNumber}
                          className="flex-1 inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          Solicită Update Sezon
                        </button>
                      </div>
                      {seasonNumber && isSeasonAvailable(parseInt(seasonNumber, 10)) && (
                        <p className="text-sm text-yellow-600">
                          Acest sezon este deja disponibil!
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4">
            <button
              type="button"
              onClick={onClose}
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
            >
              Anulează
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

SeriesRequestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    poster_path: PropTypes.string,
    release_date: PropTypes.string,
    first_air_date: PropTypes.string,
    isAvailable: PropTypes.bool,
    categoryName: PropTypes.string,
    season_count: PropTypes.number,
    availableSeasons: PropTypes.arrayOf(PropTypes.shape({
      seasonNumber: PropTypes.number.isRequired
    }))
  })
};
