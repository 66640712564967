import PropTypes from 'prop-types';

export default function ConfirmationModal({ isOpen, onClose, onConfirm, item = null }) {
  // Don't render anything if modal is not open or item is null
  if (!isOpen || !item) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Confirmare Cerere
              </h3>
              <div className="mt-4 space-y-4">
                <div className="flex items-center space-x-4">
                  {item.poster_path && (
                    <img
                      src={`https://image.tmdb.org/t/p/w200${item.poster_path}`}
                      alt={item.title || item.name}
                      className="w-24 h-36 object-cover rounded"
                    />
                  )}
                  <div className="flex-1">
                    <h4 className="text-base font-medium text-gray-900">
                      {item.title || item.name}
                    </h4>
                    <p className="text-sm text-gray-500">
                      {new Date(item.release_date || item.first_air_date).getFullYear()}
                    </p>
                    <div className="mt-2">
                      <p className="text-sm text-gray-600">
                        Acest titlu este deja disponibil în categoria{' '}
                        <span className="font-medium">{item.categoryName}</span>
                      </p>
                      {item.type === 'series' && item.availableSeasons?.length > 0 && (
                        <p className="text-sm text-gray-600 mt-1">
                          Sezoane disponibile: {item.availableSeasons.join(', ')}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <p className="text-sm text-gray-500">
                  Sunteți sigur că doriți să faceți această cerere?
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              onClick={onConfirm}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Confirmă
            </button>
            <button
              type="button"
              onClick={onClose}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Anulează
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string,
    name: PropTypes.string,
    poster_path: PropTypes.string,
    release_date: PropTypes.string,
    first_air_date: PropTypes.string,
    type: PropTypes.string,
    categoryName: PropTypes.string,
    availableSeasons: PropTypes.arrayOf(PropTypes.number)
  })
};
