import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { NotificationsProvider } from './contexts/NotificationsContext';
import Layout from './components/Layout';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import RequestForm from './pages/RequestForm';
import AdminDashboard from './pages/AdminDashboard';
import MonthlyTitles from './pages/MonthlyTitles';
import Trending from './pages/Trending';
import Issues from './pages/Issues';

const queryClient = new QueryClient();

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const AdminRoute = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  return isAuthenticated && user?.role === 'admin' ? (
    children
  ) : (
    <Navigate to="/" />
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <NotificationsProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Dashboard />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/trending"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Trending />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/issues"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Issues />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/request"
                element={
                  <PrivateRoute>
                    <Layout>
                      <RequestForm />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/monthly"
                element={
                  <PrivateRoute>
                    <Layout>
                      <MonthlyTitles />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <AdminRoute>
                    <Layout>
                      <AdminDashboard />
                    </Layout>
                  </AdminRoute>
                }
              />
            </Routes>
          </Router>
        </NotificationsProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
