import { useState, useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { config } from '../config';
import { ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../contexts/AuthContext';

const MONTHS = [
  'Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie',
  'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'
];

export default function MonthlyTitles() {
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin';
  const now = new Date();
  const [selectedMonth, setSelectedMonth] = useState(now.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(now.getFullYear());
  const [selectedStatus, setSelectedStatus] = useState('');
  const [copyStatus, setCopyStatus] = useState({ type: null, status: '' });
  const textAreaRef = useRef(null);

  // Fetch all statuses
  const { data: statuses } = useQuery({
    queryKey: ['statuses'],
    queryFn: async () => {
      const response = await axios.get(
        `${config.apiUrl}/api/${isAdmin ? 'admin' : 'requests'}/statuses`,
        { headers: config.getAuthHeaders() }
      );
      return response.data;
    }
  });

  // Set initial status based on user role
  useEffect(() => {
    if (statuses) {
      const defaultStatus = statuses.find(s => s.isDefaultForMonthlyTitles);
      if (defaultStatus) {
        setSelectedStatus(defaultStatus.name);
      } else if (statuses.length > 0) {
        setSelectedStatus(statuses[0].name);
      }
    }
  }, [statuses]);

  // Fetch monthly data
  const { data: monthlyData, isLoading } = useQuery({
    queryKey: ['monthly-titles', selectedMonth, selectedYear, selectedStatus],
    queryFn: async () => {
      if (!selectedStatus) return null;
      const response = await axios.get(
        `${config.apiUrl}/api/requests/monthly-titles?month=${selectedMonth}&year=${selectedYear}&statusName=${encodeURIComponent(selectedStatus)}`,
        { headers: config.getAuthHeaders() }
      );
      return response.data;
    },
    enabled: !!selectedStatus // Only run query if we have a status
  });

  const formatTitlesForCopy = (titles, type) => {
    if (!titles?.length) return '';
    
    const header = `${type === 'movie' ? 'Filme' : 'Seriale'} cu status "${selectedStatus}" în ${MONTHS[selectedMonth - 1]} ${selectedYear}:\n\n`;
    
    const formattedTitles = titles.map(item => {
      let text = item.title;
      if (item.year) text += ` (${item.year})`;
      if (type === 'series' && item.requestedSeason) {
        text += ` - Sezonul ${item.requestedSeason}`;
      }
      return text;
    }).join('\n');

    return header + formattedTitles;
  };

  const copyToClipboard = (text) => {
    // Create temporary textarea
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed'; // Avoid scrolling to bottom
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      document.execCommand('copy');
      document.body.removeChild(textarea);
      return true;
    } catch (err) {
      document.body.removeChild(textarea);
      return false;
    }
  };

  const handleCopy = async (type) => {
    const titles = type === 'movie' ? monthlyData?.movies : monthlyData?.series;
    
    if (!titles?.length) {
      setCopyStatus({ 
        type, 
        status: `Nu există ${type === 'movie' ? 'filme' : 'seriale'} de copiat` 
      });
      setTimeout(() => setCopyStatus({ type: null, status: '' }), 2000);
      return;
    }

    const text = formatTitlesForCopy(titles, type);
    
    try {
      // Try modern clipboard API first
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        // Fallback to older method
        const result = copyToClipboard(text);
        if (!result) throw new Error('Copy failed');
      }
      setCopyStatus({ type, status: 'success' });
    } catch (err) {
      console.error('Copy error:', err);
      setCopyStatus({ type, status: 'error' });
    }
    setTimeout(() => setCopyStatus({ type: null, status: '' }), 2000);
  };

  const years = Array.from(
    { length: now.getFullYear() - 2023 + 1 },
    (_, i) => now.getFullYear() - i
  );

  if (isLoading || !selectedStatus) {
    return (
      <div className="text-center py-10">
        <div className="text-lg text-gray-600">Se încarcă...</div>
      </div>
    );
  }

  const defaultStatus = statuses?.find(s => s.isDefaultForMonthlyTitles);

  return (
    <div className="max-w-7xl mx-auto space-y-8 px-4 sm:px-6 lg:px-8">
      <div className="space-y-2">
        <h1 className="text-2xl font-bold text-gray-900">Titluri Lunare</h1>
        <p className="text-sm text-gray-700">
          Listă cu toate titlurile adăugate în fiecare lună
          {!isAdmin && defaultStatus && (
            <span className="ml-2 text-gray-500">
              (Status: {defaultStatus.name})
            </span>
          )}
        </p>
      </div>

      <div className="flex flex-wrap gap-4">
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
          className="rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
        >
          {MONTHS.map((month, index) => (
            <option key={index + 1} value={index + 1}>
              {month}
            </option>
          ))}
        </select>

        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          className="rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>

        {isAdmin && statuses && (
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
          >
            {statuses.map((status) => (
              <option key={status._id} value={status.name}>
                {status.name}
              </option>
            ))}
          </select>
        )}
      </div>

      <div className="grid gap-8 md:grid-cols-2">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:px-6 flex justify-between items-center border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">Filme</h3>
            <button
              onClick={() => handleCopy('movie')}
              className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md ${
                copyStatus.type === 'movie' && copyStatus.status === 'success'
                  ? 'text-green-700 bg-green-100 hover:bg-green-200'
                  : 'text-primary-700 bg-primary-100 hover:bg-primary-200'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
            >
              {copyStatus.type === 'movie' && copyStatus.status === 'success' ? (
                <CheckIcon className="h-4 w-4 mr-1" />
              ) : (
                <ClipboardIcon className="h-4 w-4 mr-1" />
              )}
              {copyStatus.type === 'movie' ? (
                copyStatus.status === 'success' ? 'Copiat!' :
                copyStatus.status === 'error' ? 'Eroare' :
                copyStatus.status
              ) : 'Copiază'}
            </button>
          </div>
          <ul className="divide-y divide-gray-200 max-h-96 overflow-y-auto">
            {monthlyData?.movies?.length ? (
              monthlyData.movies.map((movie) => (
                <li key={movie._id} className="px-4 py-3">
                  <div className="text-sm font-medium text-gray-900">
                    {movie.title} {movie.year && `(${movie.year})`}
                  </div>
                </li>
              ))
            ) : (
              <li className="px-4 py-3 text-sm text-gray-500">
                Nu există filme cu acest status în această lună
              </li>
            )}
          </ul>
        </div>

        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:px-6 flex justify-between items-center border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">Seriale</h3>
            <button
              onClick={() => handleCopy('series')}
              className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md ${
                copyStatus.type === 'series' && copyStatus.status === 'success'
                  ? 'text-green-700 bg-green-100 hover:bg-green-200'
                  : 'text-primary-700 bg-primary-100 hover:bg-primary-200'
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
            >
              {copyStatus.type === 'series' && copyStatus.status === 'success' ? (
                <CheckIcon className="h-4 w-4 mr-1" />
              ) : (
                <ClipboardIcon className="h-4 w-4 mr-1" />
              )}
              {copyStatus.type === 'series' ? (
                copyStatus.status === 'success' ? 'Copiat!' :
                copyStatus.status === 'error' ? 'Eroare' :
                copyStatus.status
              ) : 'Copiază'}
            </button>
          </div>
          <ul className="divide-y divide-gray-200 max-h-96 overflow-y-auto">
            {monthlyData?.series?.length ? (
              monthlyData.series.map((series) => (
                <li key={series._id} className="px-4 py-3">
                  <div className="text-sm font-medium text-gray-900">
                    {series.title} {series.year && `(${series.year})`}
                    {series.requestedSeason && ` - Sezonul ${series.requestedSeason}`}
                  </div>
                </li>
              ))
            ) : (
              <li className="px-4 py-3 text-sm text-gray-500">
                Nu există seriale cu acest status în această lună
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
