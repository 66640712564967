import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { config } from '../config';
import NoImagePlaceholder from '../components/NoImagePlaceholder';
import ConfirmationModal from '../components/ConfirmationModal';
import SeriesRequestModal from '../components/SeriesRequestModal';
import { debounce } from 'lodash';
import { FilmIcon, PlayCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

export default function RequestForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState(location.state?.searchQuery || '');
  const [searchType, setSearchType] = useState(location.state?.mediaType || 'movie');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [seriesModalOpen, setSeriesModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { data: defaultStatus } = useQuery({
    queryKey: ['default-status'],
    queryFn: async () => {
      const response = await axios.get(`${config.apiUrl}/api/requests/default-status`, {
        headers: config.getAuthHeaders()
      });
      return response.data;
    }
  });

  const createRequestMutation = useMutation({
    mutationFn: async (requestData) => {
      const response = await axios.post(
        `${config.apiUrl}/api/requests`,
        requestData,
        {
          headers: config.getAuthHeaders()
        }
      );
      return response.data;
    },
    onSuccess: () => {
      navigate('/');
    },
    onError: (error) => {
      setConfirmModalOpen(false);
      setSeriesModalOpen(false);
      setSelectedItem(null);
      setError(error.response?.data?.message || 'Eroare la crearea cererii');
      setTimeout(() => {
        setError('');
      }, 5000);
    }
  });

  const performSearch = async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    try {
      const response = await axios.get(
        `${config.apiUrl}/api/search/${searchType}?query=${encodeURIComponent(query)}`,
        {
          headers: config.getAuthHeaders()
        }
      );
      setSearchResults(response.data);
      setError('');
    } catch (error) {
      console.error('Search error:', error);
      setError('Eroare la căutare');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query) => performSearch(query), 300),
    [searchType]
  );

  useEffect(() => {
    debouncedSearch(search);
    return () => debouncedSearch.cancel();
  }, [search, debouncedSearch]);

  // Trigger initial search if searchQuery is provided
  useEffect(() => {
    if (location.state?.searchQuery) {
      performSearch(location.state.searchQuery);
    }
  }, [location.state?.searchQuery]);

  const handleRequest = (item) => {
    setSelectedItem(item);
    if (searchType === 'series') {
      setSeriesModalOpen(true);
    } else {
      if (item.isAvailable) {
        setConfirmModalOpen(true);
      } else {
        submitRequest(item);
      }
    }
  };

  const getYear = (date) => {
    if (!date) return '';
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getFullYear()) ? '' : parsedDate.getFullYear();
  };

  const isSeasonAvailable = (item, seasonNumber) => {
    // Check if the season exists in availableSeasons
    return item.availableSeasons?.some(season => season.seasonNumber === seasonNumber);
  };

  const submitRequest = (item, type = null, season = null) => {
    const requestData = {
      title: item.title || item.name,
      type: searchType,
      tmdbId: item.id,
      year: getYear(item.release_date || item.first_air_date),
      status: defaultStatus?._id,
      posterPath: item.poster_path,
      youtubeId: item.youtubeId,
      ...(searchType === 'series' && type === 'season' && season && { requestedSeason: season })
    };

    // Add note only if:
    // 1. Item is available AND it's not a season request
    // 2. OR it's a season request for a season that's already available
    if (item.isAvailable && (!type || (type === 'season' && isSeasonAvailable(item, season)))) {
      requestData.notes = [{
        text: 'Cerut în ciuda faptului că este deja disponibil pe server!',
        createdAt: new Date()
      }];
    }

    createRequestMutation.mutate(requestData);
  };

  const handleSeriesConfirm = (type, season = null) => {
    submitRequest(selectedItem, type, season);
  };

  const handleCloseSeriesModal = () => {
    setSeriesModalOpen(false);
    setSelectedItem(null);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
    setSelectedItem(null);
  };

  const getAvailabilityInfo = (item) => {
    if (item.isAvailable) {
      return (
        <div className="space-y-1">
          <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            Disponibil
          </span>
          {item.categoryName && (
            <p className="text-xs text-gray-500">
              în {item.categoryName}
            </p>
          )}
          {searchType === 'series' && (
            <p className="text-xs text-gray-500">
              {`${item.season_count || '0'} sezoane`}
            </p>
          )}
          {/* Placeholder for consistent height when no seasons */}
          {searchType === 'series' && !item.season_count && (
            <p className="text-xs text-transparent select-none">
              placeholder
            </p>
          )}
        </div>
      );
    }
    return (
      <div className="space-y-1">
        <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
          Nu există pe server
        </span>
        <p className="text-xs text-transparent select-none">
          placeholder
        </p>
        {searchType === 'series' && (
          <p className="text-xs text-transparent select-none">
            placeholder
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="max-w-7xl mx-auto space-y-8 px-4 sm:px-6 lg:px-8">
      <div className="space-y-2">
        <h1 className="text-2xl font-bold text-gray-900">Cerere Nouă</h1>
        <p className="text-sm text-gray-700">
          Căutați și selectați titlul dorit
        </p>
      </div>

      <div className="space-y-6">
        <div className="flex flex-col space-y-4">
          <div className="flex space-x-4">
            <div className="flex space-x-2">
              <button
                onClick={() => setSearchType('movie')}
                className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                  searchType === 'movie'
                    ? 'bg-primary-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                Filme
              </button>
              <button
                onClick={() => setSearchType('series')}
                className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                  searchType === 'series'
                    ? 'bg-primary-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                Seriale
              </button>
            </div>
          </div>

          <div className="relative">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={`Căutați ${searchType === 'movie' ? 'un film' : 'un serial'}...`}
                className="block w-full pl-10 pr-4 py-3 border-0 ring-1 ring-gray-300 rounded-xl bg-white/50 backdrop-blur-sm focus:ring-2 focus:ring-primary-500 shadow-sm text-base placeholder:text-gray-400"
              />
            </div>
          </div>
        </div>

        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  {error}
                </h3>
              </div>
            </div>
          </div>
        )}

        {searchResults.length > 0 && (
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
            {searchResults.map((item) => (
              <div
                key={item.id}
                className="relative bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-200 flex flex-col h-full"
              >
                <div className="absolute top-2 right-2 flex space-x-2 z-10">
                  <a
                    href={`https://www.themoviedb.org/${searchType === 'series' ? 'tv' : 'movie'}/${item.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-1 bg-black/50 rounded-full hover:bg-black/70 transition-colors"
                    title="Vezi pe TMDB"
                  >
                    <FilmIcon className="h-5 w-5 text-white" />
                  </a>
                  <a
                    href={item.youtubeId 
                      ? `https://www.youtube.com/watch?v=${item.youtubeId}`
                      : `https://www.youtube.com/results?search_query=${encodeURIComponent(
                          `${item.title || item.name} ${getYear(item.release_date || item.first_air_date)} trailer`
                        )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-1 bg-black/50 rounded-full hover:bg-black/70 transition-colors"
                    title="Vezi trailer"
                  >
                    <PlayCircleIcon className="h-5 w-5 text-white" />
                  </a>
                </div>
                <div className="relative pt-[150%]">
                  {item.poster_path ? (
                    <img
                      src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                      alt={item.title || item.name}
                      className="absolute inset-0 w-full h-full object-cover"
                      loading="lazy"
                    />
                  ) : (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                      <NoImagePlaceholder />
                    </div>
                  )}
                </div>
                <div className="p-2 space-y-2 flex-1 flex flex-col">
                  <div className="space-y-1">
                    <h3 className="text-sm font-medium text-gray-900 line-clamp-1">
                      {item.title || item.name}
                    </h3>
                    <p className="text-xs text-gray-500">
                      {getYear(item.release_date || item.first_air_date) || (
                        <span className="text-transparent select-none">placeholder</span>
                      )}
                    </p>
                  </div>

                  {getAvailabilityInfo(item)}

                  <div className="mt-auto pt-2">
                    <button
                      onClick={() => handleRequest(item)}
                      className="w-full inline-flex justify-center items-center px-3 py-1 border border-transparent text-sm font-medium rounded text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      Solicită
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {confirmModalOpen && (
        <ConfirmationModal
          isOpen={confirmModalOpen}
          onClose={handleCloseConfirmModal}
          onConfirm={() => submitRequest(selectedItem)}
          item={selectedItem}
        />
      )}

      {seriesModalOpen && (
        <SeriesRequestModal
          isOpen={seriesModalOpen}
          onClose={handleCloseSeriesModal}
          onConfirm={handleSeriesConfirm}
          item={selectedItem}
        />
      )}
    </div>
  );
}
