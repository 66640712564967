import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MediaCarousel from '../components/MediaCarousel';
import TimeWindowToggle from '../components/TimeWindowToggle';
import MediaOptionsModal from '../components/MediaOptionsModal';
import { useAuth } from '../contexts/AuthContext';
import config from '../config';

const CarouselSkeleton = () => (
  <div className="animate-pulse">
    <div className="h-8 w-48 bg-gray-200 rounded mb-4" />
    <div className="flex gap-4 overflow-hidden">
      {[...Array(5)].map((_, i) => (
        <div key={i} className="flex-none w-48">
          <div className="aspect-[2/3] bg-gray-200 rounded-lg mb-2" />
          <div className="h-4 w-32 bg-gray-200 rounded mb-2" />
          <div className="h-4 w-24 bg-gray-200 rounded" />
        </div>
      ))}
    </div>
  </div>
);

const initialTrendingData = {
  movies: [],
  series: []
};

const initialOtherData = {
  inTheaters: [],
  upcoming: [],
  topRatedMovies: [],
  topRatedSeries: []
};

const Trending = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [timeWindow, setTimeWindow] = useState('day');
  const [loadingTrending, setLoadingTrending] = useState(true);
  const [loadingOther, setLoadingOther] = useState(true);
  const [error, setError] = useState(null);
  const [trendingData, setTrendingData] = useState(initialTrendingData);
  const [otherData, setOtherData] = useState(initialOtherData);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filterItemsWithPosters = (items) => {
    return items.filter(item => item.poster_path);
  };

  const fetchTrendingData = async () => {
    try {
      setLoadingTrending(true);
      setError(null);

      const headers = config.getAuthHeaders();
      const trending = await axios.get(`${config.apiUrl}/api/trending?timeWindow=${timeWindow}`, { headers });

      setTrendingData({
        movies: filterItemsWithPosters(trending.data?.movies || []),
        series: filterItemsWithPosters(trending.data?.series || [])
      });
    } catch (err) {
      console.error('Error fetching trending data:', err);
      let errorMessage = 'A apărut o eroare la încărcarea datelor.';
      if (err.response?.data?.message) {
        errorMessage = err.response.data.message;
      } else if (err.message === 'Network Error') {
        errorMessage = 'Nu s-a putut conecta la server. Verificați conexiunea la internet.';
      }
      setError(errorMessage);
      setTrendingData(initialTrendingData);
    } finally {
      setLoadingTrending(false);
    }
  };

  const fetchOtherData = async () => {
    try {
      setLoadingOther(true);
      setError(null);

      const headers = config.getAuthHeaders();
      const [inTheaters, upcoming, topRatedMovies, topRatedSeries] = await Promise.all([
        axios.get(`${config.apiUrl}/api/trending/in-theaters`, { headers }),
        axios.get(`${config.apiUrl}/api/trending/upcoming`, { headers }),
        axios.get(`${config.apiUrl}/api/trending/top-rated/movie`, { headers }),
        axios.get(`${config.apiUrl}/api/trending/top-rated/series`, { headers })
      ]);

      setOtherData({
        inTheaters: filterItemsWithPosters(inTheaters.data || []),
        upcoming: filterItemsWithPosters(upcoming.data || []),
        topRatedMovies: filterItemsWithPosters(topRatedMovies.data || []),
        topRatedSeries: filterItemsWithPosters(topRatedSeries.data || [])
      });
    } catch (err) {
      console.error('Error fetching other data:', err);
      let errorMessage = 'A apărut o eroare la încărcarea datelor.';
      if (err.response?.data?.message) {
        errorMessage = err.response.data.message;
      } else if (err.message === 'Network Error') {
        errorMessage = 'Nu s-a putut conecta la server. Verificați conexiunea la internet.';
      }
      setError(errorMessage);
      setOtherData(initialOtherData);
    } finally {
      setLoadingOther(false);
    }
  };

  useEffect(() => {
    fetchTrendingData();
  }, [token, timeWindow]);

  useEffect(() => {
    fetchOtherData();
  }, [token]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleRequestClick = () => {
    const mediaType = selectedItem.first_air_date ? 'series' : 'movie';
    navigate('/request', {
      state: {
        searchQuery: selectedItem.title || selectedItem.name,
        mediaType: mediaType
      }
    });
  };

  const hasContent = (items) => Array.isArray(items) && items.length > 0;
  const hasTrendingContent = trendingData && 
    (hasContent(trendingData.movies) || hasContent(trendingData.series));
  const hasOtherContent = otherData && 
    (hasContent(otherData.inTheaters) || 
     hasContent(otherData.upcoming) || 
     hasContent(otherData.topRatedMovies) || 
     hasContent(otherData.topRatedSeries));

  const hasAnyContent = hasTrendingContent || hasOtherContent;

  return (
    <div className="space-y-8">
      {error && (
        <div className="bg-red-50 rounded-lg p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Eroare
              </h3>
              <div className="mt-2 text-sm text-red-700">
                {error}
              </div>
              <div className="mt-4">
                <button
                  onClick={() => {
                    fetchTrendingData();
                    fetchOtherData();
                  }}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Încearcă din nou
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg p-6 shadow">
        <div className="flex flex-col md:flex-row md:items-center gap-4 mb-6">
          <h2 className="text-xl font-bold text-gray-900">
            Conținut în trend
          </h2>
          <TimeWindowToggle value={timeWindow} onChange={setTimeWindow} />
        </div>
        
        <div className="space-y-8">
          {loadingTrending ? (
            <>
              <CarouselSkeleton />
              <CarouselSkeleton />
            </>
          ) : (
            <>
              {hasContent(trendingData.movies) && (
                <MediaCarousel
                  title="Filme în trend"
                  items={trendingData.movies}
                  onItemClick={handleItemClick}
                />
              )}

              {hasContent(trendingData.series) && (
                <MediaCarousel
                  title="Seriale în trend"
                  items={trendingData.series}
                  onItemClick={handleItemClick}
                />
              )}
            </>
          )}
        </div>
      </div>

      {loadingOther ? (
        <div className="space-y-12">
          <CarouselSkeleton />
          <CarouselSkeleton />
          <CarouselSkeleton />
          <CarouselSkeleton />
        </div>
      ) : (
        <div className="space-y-12">
          {hasContent(otherData.inTheaters) && (
            <div className="bg-white rounded-lg p-6 shadow">
              <MediaCarousel
                title="În Cinema Acum"
                items={otherData.inTheaters}
                onItemClick={handleItemClick}
              />
            </div>
          )}

          {hasContent(otherData.upcoming) && (
            <div className="bg-white rounded-lg p-6 shadow">
              <MediaCarousel
                title="Filme ce Urmează"
                items={otherData.upcoming}
                onItemClick={handleItemClick}
              />
            </div>
          )}

          {hasContent(otherData.topRatedMovies) && (
            <div className="bg-white rounded-lg p-6 shadow">
              <MediaCarousel
                title="Cotate cele mai bune filme"
                items={otherData.topRatedMovies}
                onItemClick={handleItemClick}
              />
            </div>
          )}

          {hasContent(otherData.topRatedSeries) && (
            <div className="bg-white rounded-lg p-6 shadow">
              <MediaCarousel
                title="Cotate cele mai bune seriale"
                items={otherData.topRatedSeries}
                onItemClick={handleItemClick}
              />
            </div>
          )}
        </div>
      )}

      {!error && !loadingTrending && !loadingOther && !hasAnyContent && (
        <div className="text-center py-12 bg-white rounded-lg shadow">
          <p className="text-gray-900">
            Nu s-au găsit rezultate. Încercați să modificați filtrele sau încercați mai târziu.
          </p>
        </div>
      )}

      <MediaOptionsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        item={selectedItem}
        onRequestClick={handleRequestClick}
      />
    </div>
  );
};

export default Trending;
