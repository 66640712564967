import React from 'react';

const TimeWindowToggle = ({ value, onChange }) => {
  return (
    <div className="inline-flex rounded-lg border border-gray-200 p-1 bg-white shadow-sm">
      <button
        className={`px-4 py-2 text-sm font-medium rounded-md transition-all ${
          value === 'day'
            ? 'bg-primary-100 text-primary-700 shadow-sm'
            : 'text-gray-700 hover:bg-gray-100'
        }`}
        onClick={() => onChange('day')}
      >
        Astăzi
      </button>
      <button
        className={`px-4 py-2 text-sm font-medium rounded-md transition-all ${
          value === 'week'
            ? 'bg-primary-100 text-primary-700 shadow-sm'
            : 'text-gray-700 hover:bg-gray-100'
        }`}
        onClick={() => onChange('week')}
      >
        Săptămâna aceasta
      </button>
    </div>
  );
};

export default TimeWindowToggle;
