export function formatDate(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('ro-RO', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date);
}

export function formatTimeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} ${days === 1 ? 'zi' : 'zile'} în urmă`;
  }
  if (hours > 0) {
    return `${hours} ${hours === 1 ? 'oră' : 'ore'} în urmă`;
  }
  if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? 'minut' : 'minute'} în urmă`;
  }
  return 'chiar acum';
}
