import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { config } from '../config';
import { formatTimeAgo } from '../utils/formatters';
import { useAuth } from '../contexts/AuthContext';
import Pagination from './Pagination';

function UserForm({ onSubmit, onCancel }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ username, password, role });
    setUsername('');
    setPassword('');
    setRole('user');
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Nume utilizator
        </label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Parolă
        </label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Rol
        </label>
        <select
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
        >
          <option value="user">Utilizator</option>
          <option value="admin">Administrator</option>
        </select>
      </div>
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Anulează
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-primary-600 border border-transparent rounded-md hover:bg-primary-700"
        >
          Salvează
        </button>
      </div>
    </form>
  );
}

export default function UserManagement() {
  const [showNewUserForm, setShowNewUserForm] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const queryClient = useQueryClient();
  const { user: currentUser } = useAuth();

  const { data: users, isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await axios.get(`${config.apiUrl}/api/admin/users`, {
        headers: config.getAuthHeaders()
      });
      return response.data;
    }
  });

  const createUserMutation = useMutation({
    mutationFn: async (userData) => {
      const response = await axios.post(
        `${config.apiUrl}/api/admin/users`,
        userData,
        {
          headers: config.getAuthHeaders()
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      setShowNewUserForm(false);
      setError(null);
      setSuccess('Utilizator creat cu succes');
      setTimeout(() => setSuccess(null), 3000);
    },
    onError: (error) => {
      console.error('Create user error:', error);
      setError(error.response?.data?.message || 'Eroare la crearea utilizatorului');
      setSuccess(null);
    }
  });

  const updateRoleMutation = useMutation({
    mutationFn: async ({ userId, role }) => {
      const response = await axios.patch(
        `${config.apiUrl}/api/admin/users/${userId}/role`,
        { role },
        {
          headers: config.getAuthHeaders()
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      setError(null);
      setSuccess('Rol actualizat cu succes');
      setTimeout(() => setSuccess(null), 3000);
    },
    onError: (error) => {
      console.error('Update role error:', error);
      setError(error.response?.data?.message || 'Eroare la actualizarea rolului');
      setSuccess(null);
    }
  });

  const deleteUserMutation = useMutation({
    mutationFn: async (userId) => {
      const response = await axios.delete(
        `${config.apiUrl}/api/admin/users/${userId}`,
        {
          headers: config.getAuthHeaders()
        }
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      setError(null);
      setSuccess('Utilizator șters cu succes');
      setTimeout(() => setSuccess(null), 3000);
    },
    onError: (error) => {
      console.error('Delete user error:', error);
      setError(error.response?.data?.message || 'Eroare la ștergerea utilizatorului');
      setSuccess(null);
    }
  });

  const handleRoleChange = (userId, newRole) => {
    if (window.confirm('Sigur doriți să schimbați rolul acestui utilizator?')) {
      updateRoleMutation.mutate({ userId, role: newRole });
    }
  };

  const handleDeleteUser = (userId) => {
    if (window.confirm('Sigur doriți să ștergeți acest utilizator?')) {
      deleteUserMutation.mutate(userId);
    }
  };

  if (isLoading) {
    return (
      <div className="text-center py-4">
        <div className="text-gray-600">Se încarcă...</div>
      </div>
    );
  }

  const startIndex = (page - 1) * pageSize;
  const paginatedUsers = users?.slice(startIndex, startIndex + pageSize) || [];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Utilizatori</h2>
        {!showNewUserForm && (
          <button
            onClick={() => setShowNewUserForm(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700"
          >
            Utilizator Nou
          </button>
        )}
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                {error}
              </h3>
            </div>
          </div>
        </div>
      )}

      {success && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                {success}
              </h3>
            </div>
          </div>
        </div>
      )}

      {showNewUserForm && (
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="text-lg font-medium mb-4">Utilizator Nou</h3>
          <UserForm
            onSubmit={(data) => createUserMutation.mutate(data)}
            onCancel={() => {
              setShowNewUserForm(false);
              setError(null);
            }}
          />
        </div>
      )}

      <div className="bg-white shadow overflow-hidden rounded-md">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                #
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Utilizator
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Rol
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Creat
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actualizat
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Acțiuni
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {paginatedUsers.map((user, index) => (
              <tr key={user._id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {startIndex + index + 1}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {user.username}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {user._id !== currentUser?._id && (
                    <select
                      value={user.role}
                      onChange={(e) => handleRoleChange(user._id, e.target.value)}
                      className="text-sm rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                    >
                      <option value="user">Utilizator</option>
                      <option value="admin">Administrator</option>
                    </select>
                  )}
                  {user._id === currentUser?._id && (
                    <span>{user.role === 'admin' ? 'Administrator' : 'Utilizator'}</span>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatTimeAgo(user.createdAt)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {user.updatedAt !== user.createdAt ? formatTimeAgo(user.updatedAt) : '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {user._id !== currentUser?._id && (
                    <button
                      onClick={() => handleDeleteUser(user._id)}
                      className="text-sm text-red-600 hover:text-red-900"
                    >
                      Șterge
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={page}
        totalItems={users?.length || 0}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
      />
    </div>
  );
}
