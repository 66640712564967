import React from 'react';
import { Dialog } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, PlusCircleIcon } from '@heroicons/react/24/outline';

const MediaOptionsModal = ({ isOpen, onClose, item, onRequestClick, onTmdbClick }) => {
  const handleTmdbClick = () => {
    const baseUrl = 'https://www.themoviedb.org';
    const type = item.media_type === 'movie' ? 'movie' : 'tv';
    const url = `${baseUrl}/${type}/${item.id}`;
    window.open(url, '_blank');
    onTmdbClick?.();
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

        <div className="relative mx-auto max-w-sm rounded-lg bg-white p-6 shadow-xl">
          <Dialog.Title className="text-lg font-medium text-gray-900 mb-4">
            {item?.title || item?.name}
          </Dialog.Title>

          <div className="space-y-4">
            <button
              onClick={handleTmdbClick}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <ArrowTopRightOnSquareIcon className="h-5 w-5" />
              Vezi pe TMDB
            </button>

            <button
              onClick={() => {
                onRequestClick?.();
                onClose();
              }}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              <PlusCircleIcon className="h-5 w-5" />
              Cere acest titlu
            </button>

            <button
              onClick={onClose}
              className="w-full px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Închide
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default MediaOptionsModal;
