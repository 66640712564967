import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNotifications } from '../contexts/NotificationsContext';
import axios from 'axios';
import config from '../config';
import Pagination from '../components/Pagination';

const Issues = () => {
  const [issues, setIssues] = useState([]);
  const [newIssue, setNewIssue] = useState({ title: '', description: '' });
  const [response, setResponse] = useState('');
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [page, setPage] = useState(1);
  const [expandedIssue, setExpandedIssue] = useState(null);
  const { user } = useAuth();
  const { addNotification } = useNotifications();
  const isAdmin = user?.role === 'admin';
  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    fetchIssues();
  }, []);

  const fetchIssues = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/api/issues`, {
        headers: config.getAuthHeaders()
      });
      setIssues(response.data);
    } catch (error) {
      console.error('Error fetching issues:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.apiUrl}/api/issues`, newIssue, {
        headers: config.getAuthHeaders()
      });
      setNewIssue({ title: '', description: '' });
      fetchIssues();

      // Notify others about the new issue
      const createdIssue = response.data;
      if (createdIssue.createdBy._id !== user._id) {
        addNotification();
      }
    } catch (error) {
      console.error('Error creating issue:', error);
    }
  };

  const handleResponse = async (issueId) => {
    if (!response.trim()) return;
    
    try {
      const result = await axios.post(`${config.apiUrl}/api/issues/${issueId}/respond`, 
        { content: response },
        { headers: config.getAuthHeaders() }
      );
      setResponse('');
      setSelectedIssue(null);
      fetchIssues();

      // Find the issue that was responded to
      const issue = issues.find(i => i._id === issueId);
      // Notify if someone else responds to an issue
      if (issue && issue.createdBy._id !== user._id) {
        addNotification();
      }
    } catch (error) {
      console.error('Error responding to issue:', error);
    }
  };

  const updateStatus = async (issueId, newStatus) => {
    try {
      await axios.patch(`${config.apiUrl}/api/issues/${issueId}/status`,
        { status: newStatus },
        { headers: config.getAuthHeaders() }
      );
      fetchIssues();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const deleteIssue = async (issueId) => {
    if (!window.confirm('Sigur doriți să ștergeți această problemă?')) {
      return;
    }

    try {
      await axios.delete(`${config.apiUrl}/api/issues/${issueId}`,
        { headers: config.getAuthHeaders() }
      );
      fetchIssues();
    } catch (error) {
      console.error('Error deleting issue:', error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'open':
        return 'bg-yellow-100 text-yellow-800';
      case 'in-progress':
        return 'bg-blue-100 text-blue-800';
      case 'resolved':
        return 'bg-green-100 text-green-800';
      case 'closed':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'open':
        return 'Deschisă';
      case 'in-progress':
        return 'În Lucru';
      case 'resolved':
        return 'Rezolvată';
      case 'closed':
        return 'Închisă';
      default:
        return status;
    }
  };

  const paginatedIssues = issues.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-2">Probleme</h1>
        <p className="text-gray-600">
          Această pagină este destinată raportării problemelor legate de filme și seriale.
        </p>
      </div>
      
      {/* Create New Issue Form */}
      <div className="bg-white rounded-lg shadow p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4">Problemă Nouă</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Titlu</label>
            <input
              type="text"
              value={newIssue.title}
              onChange={(e) => setNewIssue({ ...newIssue, title: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Descriere</label>
            <textarea
              value={newIssue.description}
              onChange={(e) => setNewIssue({ ...newIssue, description: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              rows="4"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
          >
            Trimite
          </button>
        </form>
      </div>

      {/* Issues List */}
      <div className="space-y-6">
        {paginatedIssues.map((issue, index) => (
          <div key={issue._id} className="bg-white rounded-lg shadow-lg overflow-hidden relative">
            {/* Issue Header */}
            <div 
              className="bg-gray-50 p-6 border-b cursor-pointer hover:bg-gray-100 transition-colors relative"
              onClick={() => setExpandedIssue(expandedIssue === issue._id ? null : issue._id)}
            >
              <div className="flex justify-between items-start">
                <div className="flex-1">
                  <div className="flex items-center space-x-3">
                    <span className="inline-flex items-center justify-center bg-gray-200 text-gray-700 rounded-full h-6 min-w-[24px] px-2 text-sm font-medium">
                      #{(page - 1) * ITEMS_PER_PAGE + index + 1}
                    </span>
                    <h3 className="text-xl font-semibold">{issue.title}</h3>
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(issue.status)}`}>
                      {getStatusLabel(issue.status)}
                    </span>
                  </div>
                  <div className="mt-2 text-sm text-gray-500 flex items-center space-x-2">
                    <span className="font-medium text-gray-700">{issue.createdBy.username}</span>
                    <span>•</span>
                    <span>{new Date(issue.createdAt).toLocaleDateString('ro-RO', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}</span>
                  </div>
                </div>
                {isAdmin && (
                  <div className="flex items-center space-x-4">
                    <select
                      value={issue.status}
                      onChange={(e) => updateStatus(issue._id, e.target.value)}
                      className="text-sm border rounded-md p-1 bg-white"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option value="open">Deschisă</option>
                      <option value="in-progress">În Lucru</option>
                      <option value="resolved">Rezolvată</option>
                      <option value="closed">Închisă</option>
                    </select>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteIssue(issue._id);
                      }}
                      className="text-red-600 hover:text-red-800 p-1"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
              <p className="mt-4 text-gray-700 whitespace-pre-wrap pb-12">{issue.description}</p>

              {/* Comment Count Badge */}
              <div className="absolute bottom-4 right-4 flex items-center space-x-1 text-gray-500 bg-gray-100 rounded-full px-3 py-1 hover:bg-gray-200 transition-colors z-10">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                </svg>
                <span className="text-sm font-medium">{issue.responses.length}</span>
              </div>
            </div>

            {/* Responses */}
            {expandedIssue === issue._id && (
              <div className="p-6 space-y-4 bg-white">
                {issue.responses.map((response, index) => (
                  <div 
                    key={index} 
                    className={`p-4 rounded-lg ${
                      response.createdBy._id === issue.createdBy._id 
                        ? 'bg-blue-50 border-l-4 border-blue-500'
                        : 'bg-gray-50 border-l-4 border-gray-500'
                    }`}
                  >
                    <div className="flex items-center space-x-2 mb-2">
                      <span className="font-medium text-gray-900">{response.createdBy.username}</span>
                      <span className="text-gray-500">•</span>
                      <span className="text-sm text-gray-500">{new Date(response.createdAt).toLocaleDateString('ro-RO', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}</span>
                    </div>
                    <p className="text-gray-700 whitespace-pre-wrap">{response.content}</p>
                  </div>
                ))}

                {/* Add Response */}
                {issue.status !== 'closed' && (
                  <div className="mt-4 border-t pt-4">
                    <textarea
                      value={selectedIssue === issue._id ? response : ''}
                      onChange={(e) => {
                        setSelectedIssue(issue._id);
                        setResponse(e.target.value);
                      }}
                      placeholder="Adaugă un răspuns..."
                      className="w-full border rounded-md p-3 focus:border-indigo-500 focus:ring-indigo-500"
                      rows="3"
                    />
                    <button
                      onClick={() => handleResponse(issue._id)}
                      className="mt-2 bg-gray-100 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-200"
                    >
                      Răspunde
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Pagination */}
      {issues.length > ITEMS_PER_PAGE && (
        <div className="mt-6">
          <Pagination
            currentPage={page}
            totalItems={issues.length}
            pageSize={ITEMS_PER_PAGE}
            onPageChange={setPage}
          />
        </div>
      )}
    </div>
  );
};

export default Issues;
