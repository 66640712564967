const config = {
  apiUrl: import.meta.env.VITE_API_URL || 'http://localhost:5000',
  tmdbImageUrl: 'https://image.tmdb.org/t/p/w500',
  defaultHeaders: {
    'Content-Type': 'application/json',
  },
  getAuthHeaders() {
    const token = localStorage.getItem('token');
    return {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    };
  },
};

export default config;
export { config };
