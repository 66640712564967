import React, { useRef } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import NoImagePlaceholder from './NoImagePlaceholder';

const MediaCarousel = ({ title, items = [], onItemClick }) => {
  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollAmount = direction === 'left' ? -container.clientWidth : container.clientWidth;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  // Ensure items is an array and has items
  const mediaItems = Array.isArray(items) ? items : [];
  if (mediaItems.length === 0) return null;

  return (
    <div className="relative py-4 bg-white rounded-lg shadow-md p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
        <div className="flex gap-2">
          <button
            onClick={() => scroll('left')}
            className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
            aria-label="Scroll left"
          >
            <ChevronLeftIcon className="h-5 w-5 text-gray-700" />
          </button>
          <button
            onClick={() => scroll('right')}
            className="p-2 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
            aria-label="Scroll right"
          >
            <ChevronRightIcon className="h-5 w-5 text-gray-700" />
          </button>
        </div>
      </div>

      <div
        ref={scrollContainerRef}
        className="flex gap-4 overflow-x-auto scrollbar-hide scroll-smooth pb-4"
      >
        {mediaItems.map((item) => (
          <div
            key={item.id}
            className="flex-none w-48 cursor-pointer group"
            onClick={() => onItemClick?.(item)}
          >
            <div className="relative aspect-[2/3] rounded-lg overflow-hidden mb-2 bg-gray-200">
              {item.poster_path ? (
                <img
                  src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                  alt={item.title || item.name}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-200"
                  loading="lazy"
                />
              ) : (
                <NoImagePlaceholder className="w-full h-full" />
              )}
              {item.streamId && item.isAvailable && (
                <div className="absolute top-2 right-2 bg-green-500 text-white text-xs px-2 py-1 rounded shadow-md">
                  Disponibil
                </div>
              )}
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-opacity duration-200" />
            </div>
            <h3 className="font-medium text-gray-900 line-clamp-2 group-hover:text-primary-600">
              {item.title || item.name}
            </h3>
            <div className="flex items-center gap-2 text-sm text-gray-700">
              <span>
                {new Date(item.release_date || item.first_air_date).getFullYear()}
              </span>
              {item.vote_average > 0 && (
                <>
                  <span>•</span>
                  <span className="flex items-center">
                    <span className="text-yellow-500">★</span>
                    <span className="ml-1">{item.vote_average.toFixed(1)}</span>
                  </span>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MediaCarousel;
