import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';
import config from '../config';

const NotificationsContext = createContext();

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState(0);
  const [lastChecked, setLastChecked] = useState(Date.now());
  const { user } = useAuth();

  const fetchNotifications = async () => {
    if (!user) return;
    
    try {
      const response = await axios.get(`${config.apiUrl}/api/issues/notifications`, {
        headers: config.getAuthHeaders(),
        params: { since: lastChecked }
      });
      
      const { newIssues, newResponses } = response.data;
      setNotifications(newIssues + newResponses);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const clearNotifications = () => {
    setNotifications(0);
    setLastChecked(Date.now());
  };

  const addNotification = () => {
    setNotifications(prev => prev + 1);
  };

  useEffect(() => {
    if (user) {
      // Initial fetch
      fetchNotifications();

      // Set up polling every 30 seconds
      const interval = setInterval(fetchNotifications, 30000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [user, lastChecked]);

  return (
    <NotificationsContext.Provider value={{ notifications, clearNotifications, addNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
};
